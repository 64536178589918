<template>
  <div
    class="goodsItem flex justify-between space-x-8 box-border p-16 border border-gray-300 rounded mb-16"
    @click="toGoodDetail"
  >
    <el-image
      style="width: 60px; height: 60px"
      class=" rounded-md"
      :src="dataObj.image || defaultImg"
      :fit="'fill'"
    ></el-image>
    <div class="flex-1 space-y-8">
      <div class="flex justify-between">
        <div class="sapce-x-8 flex justify-start items-center mb">
          <span
            class="ellipsis w_name text-lg font-bold inline-block mr-8"
            :title="dataObj.name"
          >
            {{ dataObj.name }}
          </span>
          <i class="iconfont icon-fill_home text-warning"></i>
          <span class="text-warning  px-8 flex items-center mr-8">
            <span
              class="ellipsis w_company inline-block mr-8 leading-5"
              :title="dataObj.companyName"
            >
              {{ dataObj.companyName }}
            </span>
            <span> ></span>
          </span>
          <span class=" text-gray-900 text-sm mr-8" v-if="createdAt"
            >{{ createdAt }}收藏</span
          >
          <div>
            <span class="text-gray-900 text-sm mr-8">
              {{ dataObj.collections || "0" }}人收藏
            </span>
            <span class="text-gray-900 text-sm mr-8">
              {{ dataObj.browses || "0" }}人浏览
            </span>
          </div>
        </div>
        <div class="space-x-8">
          <slot name="btn"></slot>
        </div>
      </div>
      <!-- 配件信息 -->
      <div class="line_2">
        {{ dataObj.code }} {{ dataObj.productionCode }} {{ dataObj.quality }}
        {{ dataObj.brand }} {{ dataObj.productionPlace }}
        {{ dataObj.vehModel }} {{ dataObj.avaVehModel }}
      </div>
      <!-- 标签 -->
      <div v-if="dataObj.labels && dataObj.labels.length">
        <span
          class="text-warning bg-warning bg-opacity-30 h-16 rounded-l-full rounded-r-full px-8 mr-8"
          v-for="(label, index) in dataObj.labels"
          :key="index"
          >{{ label }}</span
        >
      </div>
      <!-- 价格 -->
      <div>
        <span class="text-warning font-bold text-lg mr-8"
          >￥{{ dataObj.price }}</span
        >
        <span class=" text-gray-900" v-if="dataObj.linePrice"
          ><s>￥{{ dataObj.linePrice }}</s></span
        >
      </div>
      <!-- 库存 -->
      <div v-if="dataObj.stockShows && dataObj.stockShows.length">
        <span
          class="bg-opacity-30 h-16 rounded-md px-8 py-4 mr-8 bg-gray-900"
          v-for="(stock, index) in dataObj.stockShows"
          :key="'s' + index"
        >
          {{ `${stock.name ? stock.name : "库存"}：` }}
          <span class="text-warning">{{ stock.value }}</span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "goodsItem",
  props: {
    dataObj: Object,
    createdAt: String,
  },
  data() {
    return {
      defaultImg: require("@/assets/maintenance/default-parts.png"),
    };
  },
  filters: {
    priceFilter(value) {
      let realVal = "--";
      if (value !== null && !isNaN(value) && value !== "") {
        realVal = parseFloat(value).toFixed(2);
      }
      return realVal;
    },
  },
  methods: {
    toGoodDetail() {
      let obj = {
        path: "/home/good",
        query: {
          goodId: this.dataObj.id,
        },
      };
      // 跳转详情，新开tab页
      const routeData = this.$router.resolve(obj);
      window.open(routeData.href, "_blank");
    },
  },
};
</script>

<style lang="less" scoped>
.w_name {
  max-width: 240px;
}
.w_company{
  max-width: 200px;
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.line_2 {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden; //溢出内容隐藏
  text-overflow: ellipsis; //文本溢出部分用省略号表示
  display: -webkit-box; //特别显示模式
  -webkit-line-clamp: 2; //行数
  line-clamp: 2;
  -webkit-box-orient: vertical; //盒子中内容竖直排列
}
</style>
